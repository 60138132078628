var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('strong',[_vm._v("Main Setting")])])])]),_c('CCardBody',[_c('div',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":false}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Refer Bonus (Tk)","vid":"refer_bonus","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Refer Bonus (Point) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"refer_bonus","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.refer_bonus),callback:function ($$v) {_vm.$set(_vm.formData, "refer_bonus", $$v)},expression:"formData.refer_bonus"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Add Code","vid":"add_code","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Add Code "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"add_code","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.add_code),callback:function ($$v) {_vm.$set(_vm.formData, "add_code", $$v)},expression:"formData.add_code"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)])])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":false}},[_c('ValidationObserver',{ref:"form1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.message)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Admin Notice","vid":"admin_notice","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"messages"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Admin Notice "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"text","id":"admin_notice","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.messages.admin_notice),callback:function ($$v) {_vm.$set(_vm.messages, "admin_notice", $$v)},expression:"messages.admin_notice"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Pakage Message","vid":"pakage_message","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"messages"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Pakage Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"text","id":"pakage_message","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.messages.pakage_message),callback:function ($$v) {_vm.$set(_vm.messages, "pakage_message", $$v)},expression:"messages.pakage_message"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Recharge Message","vid":"recharge_message","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"messages"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Recharge Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"text","id":"recharge_message","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.messages.recharge_message),callback:function ($$v) {_vm.$set(_vm.messages, "recharge_message", $$v)},expression:"messages.recharge_message"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Cash Out Message","vid":"cash_out_message","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"messages"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Cash Out Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"text","id":"cash_out_message","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.messages.cash_out_message),callback:function ($$v) {_vm.$set(_vm.messages, "cash_out_message", $$v)},expression:"messages.cash_out_message"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Add Money Message","vid":"add_money_message","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"messages"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Add Money Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"text","id":"add_money_message","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.messages.add_money_message),callback:function ($$v) {_vm.$set(_vm.messages, "add_money_message", $$v)},expression:"messages.add_money_message"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Payment Number Message","vid":"payment_number_message","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"messages"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Payment Number Message "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"text","id":"payment_number_message","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.messages.payment_number_message),callback:function ($$v) {_vm.$set(_vm.messages, "payment_number_message", $$v)},expression:"messages.payment_number_message"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Add Notice","vid":"add_notice","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"messages"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Add Notice "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-textarea',{attrs:{"type":"text","id":"add_notice","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.messages.add_notice),callback:function ($$v) {_vm.$set(_vm.messages, "add_notice", $$v)},expression:"messages.add_notice"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)])])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":false}},[_c('ValidationObserver',{ref:"form2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.image)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image One","vid":"image_one","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image One "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_one","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgone},model:{value:(_vm.images.image_one),callback:function ($$v) {_vm.$set(_vm.images, "image_one", $$v)},expression:"images.image_one"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image Two","vid":"image_two","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image Two "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_two","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgtwo},model:{value:(_vm.images.image_two),callback:function ($$v) {_vm.$set(_vm.images, "image_two", $$v)},expression:"images.image_two"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image Three","vid":"image_three","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image Three "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_three","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgthree},model:{value:(_vm.images.image_three),callback:function ($$v) {_vm.$set(_vm.images, "image_three", $$v)},expression:"images.image_three"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image Four","vid":"image_four","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image Four "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_four","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgfour},model:{value:(_vm.images.image_four),callback:function ($$v) {_vm.$set(_vm.images, "image_four", $$v)},expression:"images.image_four"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image Five","vid":"image_five","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image Five "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_five","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgfive},model:{value:(_vm.images.image_five),callback:function ($$v) {_vm.$set(_vm.images, "image_five", $$v)},expression:"images.image_five"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image six","vid":"image_six","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image six "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_six","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgsix},model:{value:(_vm.images.image_six),callback:function ($$v) {_vm.$set(_vm.images, "image_six", $$v)},expression:"images.image_six"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image seven","vid":"image_seven","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image seven "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_seven","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgseven},model:{value:(_vm.images.image_seven),callback:function ($$v) {_vm.$set(_vm.images, "image_seven", $$v)},expression:"images.image_seven"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image eight","vid":"image_eight","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image eight "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_eight","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgeight},model:{value:(_vm.images.image_eight),callback:function ($$v) {_vm.$set(_vm.images, "image_eight", $$v)},expression:"images.image_eight"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image nine","vid":"image_nine","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image nine "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_nine","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgnine},model:{value:(_vm.images.image_nine),callback:function ($$v) {_vm.$set(_vm.images, "image_nine", $$v)},expression:"images.image_nine"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image ten","vid":"image_ten","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image ten "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"image_ten","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgten},model:{value:(_vm.images.image_ten),callback:function ($$v) {_vm.$set(_vm.images, "image_ten", $$v)},expression:"images.image_ten"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Refer Image","vid":"refer_image","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"images"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Refer Image "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-file',{attrs:{"type":"text","id":"refer_image","state":errors[0] ? false : (valid ? true : null)},on:{"change":_vm.handleimgrefer},model:{value:(_vm.images.refer_image),callback:function ($$v) {_vm.$set(_vm.images, "refer_image", $$v)},expression:"images.refer_image"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image one url","vid":"image_one_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_one_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image one url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_one_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_one_url),callback:function ($$v) {_vm.$set(_vm.images, "image_one_url", $$v)},expression:"images.image_one_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image two url","vid":"image_two_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_two_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image two url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_two_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_two_url),callback:function ($$v) {_vm.$set(_vm.images, "image_two_url", $$v)},expression:"images.image_two_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image three url","vid":"image_three_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_three_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image three url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_three_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_three_url),callback:function ($$v) {_vm.$set(_vm.images, "image_three_url", $$v)},expression:"images.image_three_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image four url","vid":"image_four_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_four_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image four url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_four_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_four_url),callback:function ($$v) {_vm.$set(_vm.images, "image_four_url", $$v)},expression:"images.image_four_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image five url","vid":"image_five_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_five_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image five url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_five_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_five_url),callback:function ($$v) {_vm.$set(_vm.images, "image_five_url", $$v)},expression:"images.image_five_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image six url","vid":"image_six_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_six_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image six url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_six_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_six_url),callback:function ($$v) {_vm.$set(_vm.images, "image_six_url", $$v)},expression:"images.image_six_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image seven url","vid":"image_seven_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_seven_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image seven url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_seven_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_seven_url),callback:function ($$v) {_vm.$set(_vm.images, "image_seven_url", $$v)},expression:"images.image_seven_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image eight url","vid":"image_eight_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_eight_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image eight url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_eight_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_eight_url),callback:function ($$v) {_vm.$set(_vm.images, "image_eight_url", $$v)},expression:"images.image_eight_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image nine url","vid":"image_nine_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_nine_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image nine url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_nine_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_nine_url),callback:function ($$v) {_vm.$set(_vm.images, "image_nine_url", $$v)},expression:"images.image_nine_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('ValidationProvider',{attrs:{"name":"Image ten url","vid":"image_ten_url","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"image_ten_url"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Image ten url "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"type":"text","id":"image_ten_url","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.images.image_ten_url),callback:function ($$v) {_vm.$set(_vm.images, "image_ten_url", $$v)},expression:"images.image_ten_url"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('div',{staticClass:"col-md-12 text-right"},[_c('b-button',{staticClass:"mr-2",staticStyle:{"margin-top":"40px"},attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))])],1)])])]}}])})],1)],1)],1)])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }